// src/plugins/vuetify.js

import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {}

const preset = {
  theme: {
    themes: {
      light: {
        primary: '#4D3758',
        secondary: '#fcfcfc',
        accent: '#720D5D',
        error: '#F57C00'
      }
    }
  }
}

export default new Vuetify(preset)
