<template>
  <v-app>
    <v-app-bar
      color="#4D3758"
      dark
      :clipped-left="clipped"
      style="z-index:1000"
      fixed
      app
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title v-text="title" />
      <v-spacer />
      <v-btn icon :to="login" v-if="user === null || user === undefined" class="mr-1">
        <v-icon>mdi-login</v-icon>
      </v-btn>
      <v-btn @click="logout()" v-else class="mr-1">
        {{ user.username }}
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
      dark
      color="#3d2c46"
      v-model="drawer"
      :mini-variant="miniVariant"
      :clipped="clipped"
      fixed
      app
    >
      <v-list>
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          :to="item.to"
          router
          exact
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-text="item.title" />
          </v-list-item-content>
        </v-list-item>
        <v-list-group
          v-for="item in groups"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
        >
        <template v-slot:activator>
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item
          v-for="child in item.items"
          :key="child.title"
          :to="child.to"
        >
          <v-list-item-content>
            <v-list-item-title v-text="child.title" />
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-container>
        <router-view/>
      </v-container>
    </v-main>
    <v-footer
      dark
      color="#4D3758"
      :absolute="fixed"
      style="z-index:1000"
      app
    >
      <span>INTRANET HEXA-GAMING - 2020</span>
    </v-footer>
  </v-app>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
export default Vue.extend({
  name: 'App',

  data: () => {
    return {
      clipped: true,
      drawer: true,
      fixed: false,
      user: null,
      items: [
        {
          icon: 'mdi-home',
          title: 'Accueil',
          to: '/'
        },
        {
          icon: 'mdi-key',
          title: 'Accès VPN',
          to: '/vpnAccess'
        }
      ],
      groups: [
        {
          icon: 'mdi-account',
          title: 'Gestion des accès',
          to: '#',
          items: [
            /* {
              title: 'Liste des demandes',
              to: '/demands'
            },
            {
              title: 'Nouvelle demande',
              to: '/new-demand'
            },
            {
              title: 'Mes applications',
              to: '/myApps'
            } */
          ]
        }
      ],
      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: 'SYSTOOLS Hexa-Gaming',
      login: '/login'
    }
  },
  mounted () {
    const token = localStorage.getItem('tokenData')
    if (token !== null) {
      this.user = this.parseJwt(token)
      this.renewToken()
    }
  },
  methods: {
    renewToken: function () {
      const token = localStorage.getItem('tokenData')
      if (token !== null) {
        console.log('[TOKEN] Renewing token...')
        axios.get(process.env.VUE_APP_ROOT_API + '/auth/renew', {
          headers: { authorization: 'Bearer ' + token }
        }).then(response => {
          console.log('[+] Token renewed')
          const expiresIn = new Date((new Date()).getTime() + response.data.expiresIn).getTime()
          console.log(response.data)
          localStorage.setItem('tokenData', response.data.token)
          localStorage.setItem('tokenExpiration', expiresIn)
          setTimeout(() => {
            this.renewToken()
          }, (response.data.expiresIn / 2) * 1000)
        }).catch(error => {
          console.log(error)
          localStorage.removeItem('tokenData')
          localStorage.removeItem('tokenExpiration')
          this.$router.push(this.login)
        })
      } else {
        console.log('[TOKEN] No token to renew.')
      }
    },
    logout: function () {
      const token = localStorage.getItem('tokenData')
      if (token !== null) {
        axios.get(process.env.VUE_APP_ROOT_API + '/auth/logout', {
          headers: { authorization: 'Bearer ' + token }
        }).then(response => {
          console.log('[-] Disconnected from API')
          this.user = null
          localStorage.removeItem('tokenData')
          localStorage.removeItem('tokenExpiration')
          this.$router.push(this.login)
        }).catch(error => {
          console.log(error)
        })
      }
    },
    setToken: function (token, expire) {
      localStorage.setItem('tokenData', token)
      localStorage.setItem('tokenExpiration', expire)
      this.user = this.parseJwt(token)
    },
    parseJwt: function (token) {
      const base64Url = token.split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))
      return JSON.parse(jsonPayload)
    }
  }
})
</script>
