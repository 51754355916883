import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  /* {
    path: '/demands',
    name: 'Demandes - SYSTOOLS HEXA',
    component: () => import('../views/Demands.vue')
  },
  {
    path: '/new-demand',
    name: 'Nouvelle Demande - SYSTOOLS HEXA',
    component: () => import('../views/NewDemand.vue')
  }, */
  {
    path: '/login',
    name: 'Connexion - SYSTOOLS HEXA',
    component: () => import('../views/Login.vue')
  },
  /* {
    path: '/myApps',
    name: 'Mes Applications - SYSTOOLS HEXA',
    component: () => import('../views/Applications.vue')
  } */
  {
    path: '/vpnAccess',
    name: 'Accès VPN - SYSTOOLS HEXA',
    component: () => import('../views/VpnAccess.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
